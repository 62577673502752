<template>
  <div class="">
    <!-- 面包屑导航 -->
    <nav v-if="showBreadcrumbs" aria-label="breadcrumb" class="bg-transparent p-0 p-4 pb-0">
      <ol class="flex flex-wrap items-center text-gray-800">
        <li v-for="(crumb, index) in limitedBreadcrumbs" :key="index"
          class="breadcrumb-item flex items-center mb-2 mr-2">
          <span v-if="crumb.content === '...'" class="text-gray-600">{{ crumb.content }}</span>
          <a v-else href="#" @click.prevent="navigateTo(crumb)" class="text-blue-600 hover:text-blue-800">
            {{ crumb.content }}
          </a>
          <span class="mx-2 text-gray-400">/</span>
        </li>
      </ol>
    </nav>

    <!-- 当前节点标题和操作 -->
    <div class="flex justify-between items-center px-4">
      <div class=" items-center">
        <p class="font-bold mt-2">{{ currentNodeContent }}</p>
        <p class="text-gray-500 text-sm italic mt-2">ID: {{ currentNodeId }}</p>
      </div>
    </div>

    <hr class="my-2">
    <div class="flex justify-between items-center my-4 px-4">
      <p class="text-gray-500 font-semibold">节点</p>
      <button @click="addNode" class="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 ">
        添加
      </button>
    </div>

    <!-- 节点列表 -->
    <div class="px-4">
      <VueDraggableNext v-model="currentNodes" @end="onDragEnd" class="list-none p-0 m-0 border rounded-md"
        :handle="'.drag-handle'">
        <transition-group>
          <!-- 修改后的节点列表代码部分 -->
          <li v-for="node in currentNodes" :key="node.id" @click="handleNodeClick(node)" :data-id="node.id"
            class="cursor-pointer hover:bg-gray-100 p-2 border-b last:border-b-0 flex items-center">

            <!-- 拖动句柄在前方 -->
            <span class="drag-handle cursor-move text-gray-500 mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M7 4h2v2H7V4zm0 4h2v2H7V8zm0 4h2v2H7v-2zm4-8h2v2h-2V4zm0 4h2v2h-2V8zm0 4h2v2h-2v-2z" />
              </svg>
            </span>
            <!-- 节点内容 -->  
            <span>{{ node.content }}</span>
            <!-- <span>|| {{ node.position }}</span> -->
          </li>

        </transition-group>
      </VueDraggableNext>
    </div>

    <!-- 提示信息 -->
    <p v-if="currentNodes.length === 0" class="text-gray-500 italic mt-4 px-4">当前没有节点。</p>

    <template v-if="!isRootNode">
      <hr class="my-4">
      <div class="flex justify-center space-x-4">
        <span @click="editNode" class="cursor-pointer text-green-400 hover:text-green-600">
          编辑
        </span>
        <span @click="moveNode" class="cursor-pointer text-yellow-400 hover:text-yellow-600">
          移动
        </span>
        <span @click="deleteNode" class="cursor-pointer text-red-400 hover:text-red-600">
          删除
        </span>
      </div>
    </template>

  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { get, post } from '../utils/request';
import { VueDraggableNext } from 'vue-draggable-next';

const ROOT_NODE_PID = 0;

const breadcrumbs = ref([]);
const currentNodes = ref([]);
const currentNodeId = ref(null);
const token = ref(localStorage.getItem('token') || '');

const currentNodeContent = computed(() => {
  const currentNode = breadcrumbs.value[breadcrumbs.value.length - 1];
  return currentNode ? currentNode.content : '根节点';
});

const isRootNode = computed(() => {
  return currentNodeId.value === ROOT_NODE_PID;
});

const showBreadcrumbs = computed(() => {
  return breadcrumbs.value.length > 1; // 根节点时不显示面包屑
});


// 计算显示的面包屑，限制为最多三级
const limitedBreadcrumbs = computed(() => {
  if (breadcrumbs.value.length <= 2) {
    // 根节点和父节点
    return breadcrumbs.value.slice(0, -1);
  } else if (breadcrumbs.value.length === 3) {
    // 恰好三项时，显示根节点、上级节点和父节点
    return breadcrumbs.value.slice(0, -1);
  } else {
    // 超过三项时显示为 "根节点/.../父节点"
    return [
      breadcrumbs.value[0], // 根节点
      { content: '...', id: 'ellipsis' }, // 不可点击的占位符
      breadcrumbs.value[breadcrumbs.value.length - 2], // 当前节点的父节点
    ];
  }
});


async function loadRootNode() {
  try {
    const response = await get('/api/tree/expand?id=' + ROOT_NODE_PID, token.value);

    if (Array.isArray(response)) {
      currentNodes.value = response;
      breadcrumbs.value = [{ id: ROOT_NODE_PID, content: '根节点' }];
      currentNodeId.value = ROOT_NODE_PID;
    } else {
      throw new Error('意外的响应格式');
    }
  } catch (err) {
    console.error('加载根节点时出错:', err);
    alert('加载根节点错误: ' + err.message);
    currentNodes.value = []; // 确保 currentNodes 始终是一个数组
  }
}

async function handleNodeClick(node) {
  try {
    const response = await get(`/api/tree/expand?id=${node.id}`, token.value);
    if (Array.isArray(response)) {
      currentNodes.value = response;
      breadcrumbs.value.push({ id: node.id, content: node.content });
      currentNodeId.value = node.id;
    } else {
      throw new Error('意外的响应格式');
    }
  } catch (err) {
    console.error('加载节点时出错:', err);
    alert('加载子节点错误: ' + err.message);
    currentNodes.value = []; // 确保 currentNodes 始终是一个数组
  }
}

async function navigateTo(crumb) {
  const crumbIndex = breadcrumbs.value.findIndex(b => b.id === crumb.id);
  breadcrumbs.value = breadcrumbs.value.slice(0, crumbIndex + 1);
  try {
    const url = `/api/tree/expand?id=${crumb.id}`;
    const response = await get(url, token.value);
    if (Array.isArray(response)) {
      currentNodes.value = response;
      currentNodeId.value = crumb.id;
    } else {
      throw new Error('意外的响应格式');
    }
  } catch (err) {
    console.error('导航错误:', err);
    alert('导航错误: ' + err.message);
    currentNodes.value = []; // 确保 currentNodes 始终是一个数组
  }
}

async function addNode() {
  const content = prompt('请输入新节点的内容:');
  if (content) {
    try {
      await post('/api/tree/add', { content, pid: currentNodeId.value }, token.value);
      loadNodeChildren(currentNodeId.value);
    } catch (err) {
      console.error('添加节点错误:', err);
      alert('添加节点错误: ' + err.message);
    }
  }
}

async function editNode() {
  const content = prompt('请输入节点的新内容:');
  if (content) {
    try {
      await post('/api/tree/edit', { id: currentNodeId.value, content }, token.value);
      loadNodeChildren(breadcrumbs.value[breadcrumbs.value.length - 1].id);
      breadcrumbs.value[breadcrumbs.value.length - 1].content = content;
    } catch (err) {
      console.error('编辑节点错误:', err);
      alert('编辑节点错误: ' + err.message);
    }
  }
}

async function deleteNode() {
  if (confirm('确定要删除这个节点吗？')) {
    try {
      await post('/api/tree/delete', { id: currentNodeId.value }, token.value);
      navigateTo(breadcrumbs.value[breadcrumbs.value.length - 2]);
    } catch (err) {
      console.error('删除节点错误:', err);
      alert('删除节点错误: ' + err.message);
    }
  }
}

async function moveNode() {
  const newParentId = prompt('请输入新的父节点ID（输入null表示移动到根节点）:');
  if (newParentId !== null) {
    try {
      await post('/api/tree/move', { id: currentNodeId.value, pid: newParentId === 'null' ? null : newParentId }, token.value);
      navigateTo(breadcrumbs.value[breadcrumbs.value.length - 2]);
    } catch (err) {
      alert('移动节点错误: ' + err.message);
    }
  }
}

async function loadNodeChildren(nodeId) {
  try {
    const response = await get(`/api/tree/expand?id=${nodeId}`, token.value);
    if (Array.isArray(response)) {
      currentNodes.value = response;
      currentNodeId.value = nodeId;
    } else {
      throw new Error('意外的响应格式');
    }
  } catch (err) {
    alert('加载节点错误: ' + err.message);
    currentNodes.value = []; // 确保 currentNodes 始终是一个数组
  }
}

async function onDragEnd(event) {
  const movedElementId = event.item.dataset.id;
  const newIndex = event.newIndex;
  const updatedNodes = currentNodes.value;

  let newPosition;

  if (newIndex === 0) {

    newPosition = updatedNodes[1].position - 1;

    console.log(`移动到开头，提取第二个节点的位置${updatedNodes[1].position}，然后减1后面节点的位置得到${newPosition}`);

  } else if (newIndex === updatedNodes.length - 1) {
    // 移动到最后一个位置
    newPosition = updatedNodes[updatedNodes.length - 2].position + 1;

    console.log(`移动到最后，提取倒数第二节点的位置${updatedNodes[updatedNodes.length - 2].position}，然后加1后面节点的位置得到${newPosition}`);
  } else {
    // 移动到中间位置
    const prevPosition = updatedNodes[newIndex - 1].position;
    const nextPosition = updatedNodes[newIndex + 1].position;
    newPosition = prevPosition + (nextPosition - prevPosition) / 2;
    // newPosition = (nextPosition - prevPosition) / 2
    console.log(`移动到中间，提取上一个位置${prevPosition}和下一个位置${nextPosition}，然后求和/2等于${newPosition}`);
  }
  // 更新数据库中的位置
  try {
    await post('/api/tree/position', { id: movedElementId, position: newPosition }, token.value);

    // 重新加载节点数据以确保显示最新状态
    await loadNodeChildren(currentNodeId.value);
  } catch (err) {
    console.error('更新位置失败:', err);
    alert('更新位置失败: ' + err.message);
  }
}

onMounted(loadRootNode);
</script>
