import { createRouter, createWebHistory } from 'vue-router';

import Tree from '../views/Tree.vue'; // 导入记忆列表组件

const routes = [
  { path: '/', redirect: '/tree' },
  { path: '/tree', component: Tree }, // 添加记忆列表路由
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
