<template>
  <div class="absolute inset-0 flex items-center justify-center p-6 z-50 bg-gray-200">
    <div class="w-full max-w-md">
      <div class="bg-white p-8 rounded-lg shadow-md">
        <div class="border-b mb-6 flex justify-center">
          <button :class="{'border-b-2 border-blue-500 text-blue-500': activeTab === 'login'}"
                  class="px-4 py-2 focus:outline-none"
                  @click="activeTab = 'login'">
            登录
          </button>
          <button :class="{'border-b-2 border-green-500 text-green-500': activeTab === 'register'}"
                  class="px-4 py-2 focus:outline-none"
                  @click="activeTab = 'register'">
            注册
          </button>
        </div>

        <!-- 登录表单 -->
        <div v-if="activeTab === 'login'" class="space-y-4">
          <input v-model="loginUsername" placeholder="用户名"
                 class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition" />
          <input v-model="loginPassword" type="password" placeholder="密码"
                 class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition" />
          <button @click="login"
                  class="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
            登录
          </button>
        </div>

        <!-- 注册表单 -->
        <div v-if="activeTab === 'register'" class="space-y-4">
          <input v-model="registerUsername" placeholder="用户名"
                 class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition" />
          <input v-model="registerPassword" type="password" placeholder="密码"
                 class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition" />
          <button @click="register"
                  class="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition">
            注册
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../utils/request';

export default {
  data() {
    return {
      activeTab: 'login', // 'login' or 'register'
      loginUsername: '',
      loginPassword: '',
      registerUsername: '',
      registerPassword: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await post('/api/login', {
          username: this.loginUsername,
          password: this.loginPassword
        }, false);

        // 将 token 存储在 localStorage 中
        localStorage.setItem('token', response.token);
        
        // 触发事件，将用户名传递给父组件
        this.$emit('loginSuccess', this.loginUsername);
      } catch (error) {
        console.error('登录失败:', error);
        alert('登录失败，请检查用户名或密码');
      }
    },
    async register() {
      try {
        // 注册 API 请求
        const response = await post('/api/register', {
          username: this.registerUsername,
          password: this.registerPassword
        }, false);

        // 将 token 存储在 localStorage 中
        localStorage.setItem('token', response.token);

        // 触发事件，将用户名传递给父组件
        this.$emit('loginSuccess', this.registerUsername);
      } catch (error) {
        console.error('注册失败:', error);
        alert('注册失败，请重试');
      }
    }
  }
};
</script>

<style scoped>
/* 组件样式，可以根据需要调整 */
</style>
